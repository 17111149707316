import React, { useState } from 'react';

const InvoiceDetails = ({ setInvoiceDetails }) => {
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceDate, setInvoiceDate] = useState('');

  const handleDetailsChange = () => {
    setInvoiceDetails({ invoiceNumber, invoiceDate });
  };

  return (
    <div>
      <h2>Invoice Details</h2>
      <input
        type="text"
        placeholder="Invoice Number"
        value={invoiceNumber}
        onChange={(e) => setInvoiceNumber(e.target.value)}
        onBlur={handleDetailsChange}
      />
      <input
        type="date"
        placeholder="Invoice Date"
        value={invoiceDate}
        onChange={(e) => setInvoiceDate(e.target.value)}
        onBlur={handleDetailsChange}
      />
    </div>
  );
};

export default InvoiceDetails;
