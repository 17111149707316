import React, { useState } from 'react';

const ClientDetails = ({ setClientDetails }) => {
  const [name, setName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(''); // Add phone number

  const handleDetailsChange = () => {
    setClientDetails({ name, address1, address2, phoneNumber });
  };

  return (
    <div>
      <h2>Client Details</h2>
      <input
        type="text"
        placeholder="Client Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        onBlur={handleDetailsChange}
      />
      <input
        type="text"
        placeholder="Client Address Line 1"
        value={address1}
        onChange={(e) => setAddress1(e.target.value)}
        onBlur={handleDetailsChange}
      />
      <input
        type="text"
        placeholder="Client Address Line 2"
        value={address2}
        onChange={(e) => setAddress2(e.target.value)}
        onBlur={handleDetailsChange}
      />
      <input
        type="text"
        placeholder="Client Phone Number"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        onBlur={handleDetailsChange}
      />
    </div>
  );
};

export default ClientDetails;
