import React, { useState } from 'react';

const BusinessDetails = ({ setBusinessDetails }) => {
  const [logo, setLogo] = useState(null);
  const [businessName, setBusinessName] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [bankDetails, setBankDetails] = useState('');

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result); // Base64 string of the logo
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDetailsChange = () => {
    setBusinessDetails({ logo, businessName, address, email, bankDetails });
  };

  return (
    <div>
      <h2>Business Details</h2>
      
      {/* Logo Upload */}
      <div>
        <label>Upload Logo: </label>
        <input 
          type="file" 
          accept="image/*" 
          onChange={handleLogoUpload}
          onBlur={handleDetailsChange}
        />
      </div>
      
      {/* Company Name */}
      <div>
        <input
          type="text"
          placeholder="Company Name"
          value={businessName}
          onChange={(e) => setBusinessName(e.target.value)}
          onBlur={handleDetailsChange}
        />
      </div>

      {/* Address */}
      <div>
        <textarea
          placeholder="Address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          onBlur={handleDetailsChange}
        />
      </div>

      {/* Email */}
      <div>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={handleDetailsChange}
        />
      </div>

      {/* Bank Details */}
      <div>
        <textarea
          placeholder="Bank Details"
          value={bankDetails}
          onChange={(e) => setBankDetails(e.target.value)}
          onBlur={handleDetailsChange}
        />
      </div>
    </div>
  );
};

export default BusinessDetails;
