import React, { useState } from 'react';

const InvoiceSummary = ({ items }) => {
  const [taxRate, setTaxRate] = useState(0); // Initialize tax rate with 0%

  const subtotal = items.reduce((total, item) => total + (item.quantity * item.price), 0);
  const tax = (subtotal * taxRate) / 100;
  const total = subtotal + tax;

  return (
    <div>
      <h2>Summary</h2>
      <div>
        <label>Tax Rate (%): </label>
        <input className='tax0d'
          type="number"
          value={taxRate}
          onChange={(e) => setTaxRate(parseFloat(e.target.value))}
          placeholder="Enter tax percentage"
        />
      </div>
      <p>Subtotal: ${subtotal.toFixed(2)}</p>
      <p>Tax ({taxRate}%): ${tax.toFixed(2)}</p>
      <p>Total: ${total.toFixed(2)}</p>
    </div>
  );
};

export default InvoiceSummary;
