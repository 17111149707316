import React, { useState } from 'react';
import BusinessDetails from './BusinessDetails';
import ClientDetails from './ClientDetails';
import InvoiceDetails from './InvoiceDetails';
import ItemList from './ItemList';
import InvoiceSummary from './InvoiceSummary';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import './App.css';

function App() {
  const [items, setItems] = useState([{ description: '', quantity: 1, price: 0 }]);
  const [taxRate, setTaxRate] = useState(0);
  const [businessDetails, setBusinessDetails] = useState({
    logo: null,
    businessName: '',
    address: '',
    email: '',
    bankDetails: '',
  });
  const [clientDetails, setClientDetails] = useState({
    name: '',
    address1: '',
    address2: '',
    phoneNumber: '',
  });
  const [invoiceDetails, setInvoiceDetails] = useState({
    invoiceNumber: '',
    invoiceDate: '',
  });

  const generatePDF = () => {
    const doc = new jsPDF();

    // Header
    doc.setFontSize(18);
    doc.text('Invoice', 105, 15, null, null, 'center');

    // Business Logo
    if (businessDetails.logo) {
      doc.addImage(businessDetails.logo, 'PNG', 15, 30, 30, 30);
    }

    // Business Details (dynamically populated)
    doc.setFontSize(12);
    doc.setFont('helvetica', 'regular');
    doc.text(businessDetails.businessName, 15, 60);
    doc.text(businessDetails.address, 15, 65);
    doc.text(`Email: ${businessDetails.email}`, 15, 70);


    // Add "Bill to" heading
    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    doc.text('Bill to:', 15, 80);

    // Client Details (dynamically populated)
    doc.setFontSize(12);
    doc.setFont('helvetica', 'regular');
    doc.text(`Brand Name: ${clientDetails.name}`, 15, 90);
    doc.text(`Address: ${clientDetails.address1}`, 15, 95);
    doc.text(`${clientDetails.address2}`, 15, 100);
    doc.text(`Phone: ${clientDetails.phoneNumber}`, 15, 105);

    // Invoice Details (dynamically populated)
    doc.text(`Invoice Number: ${invoiceDetails.invoiceNumber}`, 150, 85);
    doc.text(`Date: ${invoiceDetails.invoiceDate}`, 150, 90);

    // Add Items to PDF
    doc.autoTable({
      startY: 110,
      head: [['Description', 'Quantity', 'Price', 'Total']],
      body: items.map(item => [
        item.description,
        item.quantity,
        `$${item.price.toFixed(2)}`,
        `$${(item.quantity * item.price).toFixed(2)}`
      ]),
      styles: { halign: 'center' },
      headStyles: { fillColor: [22, 160, 133] },
    });

    // Summary Section
    const subtotal = items.reduce((total, item) => total + (item.quantity * item.price), 0);
    const tax = (subtotal * taxRate) / 100;
    const total = subtotal + tax;

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 10,
      body: [
        ['Subtotal:', `$${subtotal.toFixed(2)}`],
        [`Tax (${taxRate}%):`, `$${tax.toFixed(2)}`],
        ['Total:', `$${total.toFixed(2)}`]
      ],
      styles: { halign: 'right' },
      tableWidth: 'auto',
      margin: { left: 120 },
      bodyStyles: { fillColor: [241, 196, 15] },
    });

    // Bank Details Section
    doc.text('Bank Details:', 20, doc.autoTable.previous.finalY + 30);
    doc.text(businessDetails.bankDetails, 20, doc.autoTable.previous.finalY + 35);

    doc.save('invoice.pdf');
  };

  return (
    <div className="App">
      <h1>Invoice Generator</h1>
      <div className='header-part'>
      <div className="section business">
        <BusinessDetails setBusinessDetails={setBusinessDetails} />
      </div>
      <div className="section client">
        <ClientDetails setClientDetails={setClientDetails} />
      </div>
      </div>
      <div className='invoice-part'>
      <div className="section details">
        <InvoiceDetails setInvoiceDetails={setInvoiceDetails} />
      </div>
      <div className="section items">
        <ItemList items={items} setItems={setItems} />
      </div>
      </div>
      <div className="section summary">
        <InvoiceSummary items={items} taxRate={taxRate} setTaxRate={setTaxRate} />
      </div>
      <button onClick={generatePDF}>Generate PDF</button>
    </div>
  );
}

export default App;
