import React from 'react';

const ItemList = ({ items, setItems }) => {
  const addItem = () => {
    setItems([...items, { description: '', quantity: 1, price: 0 }]);
  };

  const removeItem = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  const handleChange = (index, field, value) => {
    const newItems = items.map((item, i) => i === index ? { ...item, [field]: value } : item);
    setItems(newItems);
  };

  return (
    <div>
      <h2>Items</h2>
      {items.map((item, index) => (
        <div key={index} className="item">
          <input
            type="text"
            placeholder="Description"
            value={item.description}
            onChange={(e) => handleChange(index, 'description', e.target.value)}
          />
          <input
            type="number"
            placeholder="Quantity"
            value={item.quantity}
            onChange={(e) => handleChange(index, 'quantity', parseFloat(e.target.value))}
          />
          <input
            type="number"
            placeholder="Price"
            value={item.price}
            onChange={(e) => handleChange(index, 'price', parseFloat(e.target.value))}
          />
          <button onClick={() => removeItem(index)}>Remove</button>
        </div>
      ))}
      <button onClick={addItem}>Add Item</button>
    </div>
  );
};

export default ItemList;
